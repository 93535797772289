<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
          <div class="grid-content bg-purple">
            <div style="display: inline-block" class="title_h"></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <!-- <el-button size="medium" t ype="success" @click="print">Печать</el-button> -->
              <el-button
                size="medium"
                :loading="save_loading"
                :disabled="save_loading ? true : false"
                type="success"
                @click="updateData"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="block_Editor_Naw">
      <el-row>
        <el-col :span="24">
          <div class="documentation_content">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item title="Контент " name="2">
                <div class="Editor_widht">
                  <Tinymce
                    ref="editor"
                    class="naw_Editor"
                    v-model="selected.content"
                    :height="600"
                  />
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
export default {
  mixins: [form],

  props: ["selected"],
  name: "createForm",
  components: {
    Tinymce,
  },
  data() {
    return {
      activeName: "2",
      serchoInput: "",
      save_loading: false,
      update_loading: false,
      remove_loading: false,
      template: "",
      form: {},
    };
  },
  computed: {
    ...mapGetters({
      list: "formLogo/list",
      categories: "categoryDocumentation/list",
      model: "formLogo/model",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  watch: {
    model: {
      handler: function () {
        this.form = JSON.parse(JSON.stringify(this.model));
      },
      deep: true,
    },
  },
  created() {
    if (this.categories && this.categories.length == 0) {
      this.categoryList();
    }
    this.form = JSON.parse(JSON.stringify(this.model));
  },
  methods: {
    ...mapActions({
      updateList: "formLogo/index",
      save: "formLogo/store",
      update: "formLogo/update",
      remove: "formLogo/destroy",
      categoryList: "categoryDocumentation/index",
    }),
    updateData() {
      this.update_loading = true;

      this.form.id = this.selected.id;
      this.form.content = this.selected.content;
      this.update(this.form)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.parent().listChanged();
          this.resetForm("form");
          this.update_loading = false;
          this.loadModel();
        })
        .catch((err) => {
          this.update_loading = false;
          console.log(err);
        });
    },
    print() {
      let DATA = this.form.content;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(DATA);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

    loadModel() {
      this.form = {};
      this.template = "";
    },
    resetForm(formName) {
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
<style lang="scss">
.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  height: 600px !important;
}
// .content-table{
//     line-height: 2px;
// }
.content-table ul,
.content-table p {
  line-height: 24px;
}
.content-table table {
  width: 100%;
}
.el-card.is-always-shadow {
  min-height: 700px;
}
.content-table tr,
.content-table td {
  border: 1px solid black;
}
.documentatBlock .max_buttom:hover {
  text-decoration: none;
}
.TitleBocks {
  font-size: 18px;
  line-height: 20px;
  span {
    font-size: 14px;
    color: #8e8e8e;
  }
}
.documents_block {
  min-height: 750px;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 4px;
  overflow: hidden;

  .document_title {
    background-color: #cecece6b;
  }
  .item-documents {
    border-top: 1px solid #f2f2f2;
    transition: background-color 0.5s;
    cursor: pointer;
    &:hover {
      background-color: #cecece6b;
    }
  }
}
</style>